import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero1 from "../components/Hero/Hero1"
import Recognition from "../components/Recognition"
import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
import Card1 from "../components/Card/Card1"
import { StaticImage } from "gatsby-plugin-image"
import Cta1 from "../components/Cta/Cta1"
import { useState } from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="bg-[url('/images/bot-bg.png')] bg-cover p-[9rem] lg:mt-[-3rem]">
        
        <h2 className="text-center teamtitle text-white">
          Our Team
          </h2><br/>
          
          
          <p className="text-center teamp text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          
          <div className="flex items-center justify-center">
          <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">Explore More</button>
      


                </div>

         
                </div>
  <Container>
  <div className="grid lg:grid-cols-3 sm:grid-cols-2  gap-14 mt-21 place-items-center ">
    <div className="items-end">
            <h1 className="blogft">
            Us in Numbers
            </h1>
            <p className="text-gray-700 text-base mtr1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
            </p>
          
          </div>
          <div className="items-end col-span-2">
          <div className="grid lg:grid-cols-3 sm:grid-cols-3  gap-14 mt-21 place-items-center ">
         <div>
         <div className="ctext">7</div>
         <p className="pctext">Industries</p>
         </div>
             
         

         <div>
         <div className="ctext">15 M+</div>
         <p className="pctext">Content pieces Processed</p>
         </div>
         <div>
         <div className="ctext">25 M+</div>
         <p className="pctext">Processed Iterations</p>
         </div>
         <div className="col-span-2">
         <div className="ctext ">300 years</div>
         <p className="pctext">of Combined Experience</p>
         </div>
         <div>
         <div className="ctext">100%</div>
         <p className="pctext">Robot Utilization</p>
         </div>

            </div>
            
          </div>
         
        
        </div>
    <div className="grid lg:grid-cols-2 sm:grid-cols-2  gap-14 mt-21 place-items-center ">

        
    <div className="items-end">
            <h1 className="blogft">
            <span className="text-red">We</span> scratch, build and play together
            </h1>
            <p className="text-gray-700 text-base mtr1">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
              content.
            </p>
            <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">
              Read More
            </button>
          </div>
          <div className="items-end ">
            <img
              className="blogimg "
              src="/images/mask.png"
              alt="Sunset in the mountains"
            />
          </div>
         
        
        </div>
        <div className="grid lg:grid-cols-2 sm:grid-cols-2  gap-14 mt-21 place-items-center ">

        <div className="items-end ">
        <img
          className="blogimg "
          src="/images/mask.png"
          alt="Sunset in the mountains"
        />
      </div>
<div className="items-end">
        <h1 className="blogft">
        <span className="text-red">We</span> scratch, build and play together
        </h1>
        <p className="text-gray-700 text-base mtr1">
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
          content.
        </p>
        <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">
          Read More
        </button>
      </div>
     
     
    
    </div>
        </Container>
<br/>

<Container>


        
       
    
<div>
        
        <h2 className="text-center teamtitle text-black">
          Our Team
          </h2><br/>
          
          
          <p className="text-center teamp text-black">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          
          <div className="flex items-center justify-center">
          <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">Explore More</button>
      


                </div>

         
                </div>

                <div className="cbox grid lg:grid-cols-2  md:grid-cols-1 gap-10 place-items-center ">
  <div className="  "> 
  Lorem ipsum dolor sit amet, adipiscing do eiusmod tempor incididunt
  
  </div>
  <div className=" base">
  
  <button className=" combtn bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">
          Read More
        </button>

        
  </div>

  
</div>

     

  </Container>
<br/><br/>

  
  </Layout>
)

export default IndexPage
